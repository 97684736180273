import Table from 'react-bootstrap/Table';
import { useState } from 'react';
import './adminTable.scss';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import TablePagination from './TablePagination';

const AdminTable = (props: any) => {
    const [searchInput, setSearchInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const numToDisplay = 20; //number of elements we want to show per page. 
    const numPages = Math.ceil(props.data.rows.length / numToDisplay); //round up the equation for leftover elements.

    const handleIndividualSelect = (e: any, obj: any) => {
        e.target.checked ? props.setSelectedArray((selectedArray: any) => [...selectedArray, obj]) : props.setSelectedArray((selectedArray: any) => selectedArray.filter((item: any) => item.id !== obj.id));
    }
    const handleSelectAll = (e: any) => {
        e.target.checked ? props.setSelectedArray([...props.data.rows.filter(searchFilterKey).filter(filterKey)]) : props.setSelectedArray([]);
    }
    const searchFilterKey = (row: any) => {
        return row.first_name.toLowerCase().includes(searchInput)
            || row.last_name.toLowerCase().includes(searchInput)
            || row.name.toLowerCase().includes(searchInput)
            || row.status.toLowerCase().includes(searchInput)
    }

    const filterKey = (row: any) => {
        let resp = true;
        if (props.filterArr.length > 0) {
            props.filterArr.forEach((filter: any) => {
                if (resp) {
                    if (filter.key === 'registered_date') {
                        let filterDate = new Date().setHours(0, 0, 0, 0);
                        console.log(filter.value)

                        switch (filter.value.toLowerCase()) {
                            case 'today':
                                resp = new Date(row[filter.key]).setHours(0, 0, 0, 0) === filterDate;
                                break;
                            case 'past 7 days':
                                filterDate = new Date(filterDate).setDate(new Date(filterDate).getDate() - 7)
                                resp = new Date(row[filter.key]).setHours(0, 0, 0, 0) >= filterDate;
                                break;
                            case 'this month':
                                filterDate = new Date(filterDate).getMonth()
                                resp = new Date(row[filter.key]).getMonth() === filterDate;
                                break;
                            case 'this year':
                                filterDate = new Date(filterDate).getFullYear()
                                resp = new Date(row[filter.key]).getFullYear() === filterDate;
                                break;
                        }

                    } else {
                        resp = row[filter.key].toLowerCase() === filter.value.toLowerCase()
                    }

                }
            });
        }
        return resp;

    }

    // const paginationFilter = (row: any, index: number) => {
    //     let startIndex = (currentPage - 1) * (numToDisplay);
    //     let endIndex = startIndex + numToDisplay - 1;
    //     return index >= startIndex && index <= endIndex ? true : false;
    // }
    // console.log(props.data);

    return (
        <>
            <input type="text" className='searchbar' placeholder="Search.." onChange={(e) => setSearchInput(e.target.value.toLowerCase())} />
            <Table striped className='adminTable'>
                <thead>
                    <tr>
                        {props.data.columns.map((col: any, index: number) => {
                            return (
                                <th>{col.label} {index === 0 && <input className="" type="checkbox" onChange={(e) => { handleSelectAll(e) }} />}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {props.data.rows && props.data.rows.filter(searchFilterKey).filter(filterKey).map((row: any, index: number) => {
                        //pagination
                        let startIndex = (currentPage - 1) * (numToDisplay);
                        let endIndex = startIndex + numToDisplay - 1;

                        return (
                            <tr key={row.id} className={((index >= startIndex && index <= endIndex) || searchInput !== "") ? "" : "d-none"}>
                                <td>
                                    <div className="d-flex mt-1 selectIndividual">
                                        <input id={row.id} className='m-auto' type="checkbox" checked={props.selectedArray.filter((e: any) => e.id === row.id).length > 0} onChange={(e) => { handleIndividualSelect(e, row) }} />
                                    </div>
                                </td>
                                <td>{row.first_name + ' ' + row.last_name}</td>
                                <td>{row.name}</td>
                                <td>{row.registered_date.split("T")[0]}</td>
                                <td>
                                    <select className="w-75" value={row.status} onChange={(e) => props.handleStatusEvent(e, row)}>
                                        <option value="Pending">Pending</option>
                                        <option value='Active'>Active</option>
                                        <option value='Suspended'>Suspended</option>
                                        <option value='Unverified'>Unverified</option>
                                        <option value='Refused'>Refused</option>
                                        <option value='Reminder'>Reminder</option>
                                        <option value='Quarterly'>Quarterly</option>
                                        <option value='Error'>Error</option>
                                    </select>
                                </td>
                                <td className='actionButtons'>
                                    <div className="d-flex actionButtons">
                                        <button onClick={() => { props.handleEditClick(row) }}><FiEdit2 /></button>
                                        <button onClick={() => { props.handleIndividualDelete(row) }}><FiTrash2 /></button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

            {props.data.rows &&
                <>
                    <span className='showingLabel'>Showing {numToDisplay > props.data.rows.filter(searchFilterKey).filter(filterKey).length ? props.data.rows.filter(searchFilterKey).filter(filterKey).length : numToDisplay} of {props.data.rows.filter(searchFilterKey).filter(filterKey).length} items</span>
                    <TablePagination tableData={props.data} numPages={numPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </>

            }
        </>
    );
}

export default AdminTable;