import { useState } from "react";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { csvheaders, tableColumns } from "../ContentBlocks/metaData";
import "./ContentBlocks.scss";
import {
  deleteMultipleContentBlocks,
  getContent,
} from "../../services/ContentBlocks";
import ContentBlocksTable from "./ContentBlocksTable";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const ContentBlocks = () => {
  const { data, mutate } = useSWR("getContentBlocks", getContent);
  const [selectedArray, setSelectedArray] = useState<any[]>([]);
  const navigate = useNavigate();
  const tableData = {
    columns: tableColumns,
    rows: data,
  };

  const handleSelectedDelete = () => {
    try {
      if (
        window.confirm(
          "Are you sure you want to delete " + selectedArray.length + " items?"
        )
      ) {
        deleteMultipleContentBlocks(selectedArray, mutate);
      }
    } catch (e) {
      alert("Something Went Wrong");
    }
  };

  const handleIndividualDelete = (obj: any) => {
    try {
      if (window.confirm("Are you sure you want to delete this item?")) {
        //reusing the delete function, just submitting the single object as an array
        deleteMultipleContentBlocks([obj], mutate);
      }
    } catch (e) {
      alert(e);
    }
  };

  const handleNewClick = () => {
    return navigate("/admin/content-blocks/edit", { state: {} });
  };
  const handleEditClick = (obj: any) => {
    return navigate("/admin/content-blocks/edit", { state: obj });
  };

  return (
    <div className="max-width w-100 me-auto ms-auto mt-5">
      <Breadcrumb
        label={"Content Blocks"}
        path={"admin"}
      />
      <div className="d-flex align-items-baseline">
        <h1 className="me-5">Content Blocks</h1>
        <div className="d-flex ms-auto">
          <button className="secondary me-2" onClick={handleNewClick}>
            New
          </button>
          <button className="me-5 deleteButton" onClick={handleSelectedDelete}>
            {" "}
            Delete{" "}
          </button>
          {selectedArray.length > 0 ? (
            <CSVLink
              className="reportButton secondary me-2"
              data={selectedArray}
              headers={csvheaders}
              filename={"CEP-ContentBlocks.csv"}
            >
              Create a Report
            </CSVLink>
          ) : (
            <button className=" reportButton secondary disabled me-2" disabled>
              Create a Report
            </button>
          )}
        </div>
      </div>
      <div className="table-wrapper">
        <div className="table-wrapper">
          {data && (
            <ContentBlocksTable
              data={tableData}
              selectedArray={selectedArray}
              setSelectedArray={setSelectedArray}
              handleEditClick={handleEditClick}
              handleIndividualDelete={handleIndividualDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentBlocks;
