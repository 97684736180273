import React, { useState } from "react";
import useSWR from "swr";
import {
  getCompanyList,
  deleteMultipleCompanies,
  updateCompany,
  updateMultipleStatus,
} from "../../services/Company";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { csvheaders, tableColumns } from "./metaData";
import "./Company.scss";
import CompanyTable from "./CompanyTable";
import Filters from "../Shared/Filters";
import { filterKeys } from "./metaData";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const Company = () => {
  const { data, mutate } = useSWR("getCompanyList", getCompanyList);
  const [selectedArray, setSelectedArray] = useState<any[]>([]);
  const [filterArr, setFilterArr] = useState(Array<any>);
  const navigate = useNavigate();
  const tableData = {
    columns: tableColumns,
    rows: data,
  };

  const handleStatusEvent = async (e: any, obj: any) => {
    if (selectedArray.length > 0) {
      const text =
        "Do you want to change the status of " +
        selectedArray.length +
        " items to " +
        e.target.value +
        " ?";
      if (window.confirm(text)) {
        await updateMultipleStatus(selectedArray, e.target.value, mutate);
      }
    } else {
      obj.status = e.target.value;
      await updateCompany(obj);
    }
    return;
  };

  const handleSelectedDelete = () => {
    try {
      if (
        window.confirm(
          "Are you sure you want to delete " + selectedArray.length + " items?"
        )
      ) {
        deleteMultipleCompanies(selectedArray, mutate);
      }
    } catch (e) {
      alert(e);
    }
  };

  const handleIndividualDelete = (obj: any) => {
    try {
      if (window.confirm("Are you sure you want to delete this item?")) {
        //reusing the delete function, just submitting the single object as an array
        deleteMultipleCompanies([obj], mutate);
      }
    } catch (e) {
      alert(e);
    }
  };

  const handleNewClick = () => {
    return navigate("/admin/companies/edit", { state: {} });
  };
  const handleEditClick = (obj: any) => {
    return navigate("/admin/companies/edit", { state: obj });
  };

  return (
    <div className="max-width w-100 me-auto ms-auto mt-5">
      <Breadcrumb label={"Company"} path={"admin"} />
      <div className="d-flex align-items-baseline">
        <h1 className="me-5">Company</h1>
        <div className="d-flex ms-auto">
          <button className="secondary me-2" onClick={handleNewClick}>
            New
          </button>
          <button className="me-5 deleteButton" onClick={handleSelectedDelete}>
            Delete
          </button>
          {selectedArray.length > 0 ? (
            <CSVLink
              className="reportButton secondary me-2"
              data={selectedArray}
              headers={csvheaders}
              filename={"CEP-Company.csv"}
            >
              Create a Report
            </CSVLink>
          ) : (
            <button className=" reportButton secondary disabled me-2" disabled>
              Create a Report
            </button>
          )}
          <Filters
            filterArr={filterArr}
            setFilterArr={setFilterArr}
            filterKeys={filterKeys}
          />
        </div>
      </div>
      <div className="table-wrapper">
        {data && (
          <CompanyTable
            data={tableData}
            selectedArray={selectedArray}
            setSelectedArray={setSelectedArray}
            handleEditClick={handleEditClick}
            handleIndividualDelete={handleIndividualDelete}
            handleStatusEvent={handleStatusEvent}
            filterArr={filterArr}
            setFilterArr={setFilterArr}
          />
        )}
      </div>
    </div>
  );
};

export default Company;
