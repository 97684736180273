import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './ItemHistory.scss';
import useSWR from 'swr';
import { getAllItemHistory } from '../../services/itemHistory';
import Card from 'react-bootstrap/Card';


//All the Filter component does is display the filters according to the metaData array and then manipulate the filterArr state variable. No filtering actually happens in this component. 
const ItemHistory = (props: any) => {
    const [show, setShow] = useState(false);
    let route = '/item-history'
    if(props.table_id){
        props.item_id
        ? route = `/item-history/${props.table_id}/${props.item_id}`
        : route =`/item-history/${props.table_id}`;
    }

    const { data } = useSWR(route, getAllItemHistory);

    return (
        <>
            <button className='itemHistoryButton' onClick={()=>{setShow(true)}}>Item History</button>
            <Modal show={show} onHide={()=>{setShow(false)}}>
                <Modal.Body className="itemHistoryBody">
                <h3 className='mb-4'>Item History</h3>
                {data && data.length >0 && data.map(((item:any) =>{
                    return (
                       <Card className='mb-3'>
                        <Card.Body className='row'>
                           <div className='col-4'>
                            <span className='itemhistorydate'>{item.action_date.split('T')[0]}</span>
                           </div>
                           <div className='col-8'>
                            <span className='itemhistorydesc'>Item {item.action} by: <span className='itemhistoryuser'>{item.user}</span></span>
                           </div>
                        </Card.Body>
                       </Card>
                    )
                }))}
                {data && data.length === 0 && <p>There is no Item History for this Item</p>}
                </Modal.Body>

                <button className='ms-2 me-2 mb-2' onClick={() => { setShow(false); }}>
                    Close
                </button>
            </Modal>
        </>
    );
}

export default ItemHistory;
