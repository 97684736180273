import { useState} from "react";
import useSWR from "swr";
import {
  getRegistrants,
  deleteMultipleRegistrants,
  updateRegistrant,
  updateMultipleStatus,
} from "../../services/registrants";
import { useNavigate } from "react-router-dom";
import { tableColumns, filterKeys } from "./metaData";
import Filters from "../Shared/Filters";
import "./Registrants.scss";
import AdminTable from "../Shared/AdminTable";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import EmailNotifications from "./EmailNotifications";
import LoadByDate from "./LoadByDate";
import ExportExcel from "../Shared/ExportExcel";


const Registrants = () => {
  const { data, mutate } = useSWR("getRegistrant", getRegistrants);
  const [selectedArray, setSelectedArray] = useState<any[]>([]);
  const [filterArr, setFilterArr] = useState(Array<any>);
  const navigate = useNavigate();
  const tableData = {
    columns: tableColumns,
    rows: data,
  };

  const handleStatusEvent = async (e: any, obj: any) => {
    if (selectedArray.length > 0) {
      const text =
        "Do you want to change the status of " +
        selectedArray.length +
        " items to " +
        e.target.value +
        " ?";
      if (window.confirm(text)) {
        await updateMultipleStatus(selectedArray, e.target.value, mutate);
      }
    } else {
      obj.status = e.target.value;
      await updateRegistrant(obj);
      mutate();
    }
    return;
  };

  const handleSelectedDelete = () => {
    try {
      if (
        window.confirm(
          "Are you sure you want to delete " + selectedArray.length + " items?"
        )
      ) {
        deleteMultipleRegistrants(selectedArray, mutate);
      }
    } catch (e) {
      alert(e);
    }
  };

  const handleIndividualDelete = (obj: any) => {
    try {
      if (window.confirm("Are you sure you want to delete this item?")) {
        //reusing the delete function, just submitting the single object as an array
        deleteMultipleRegistrants([obj], mutate);
      }
    } catch (e) {
      alert(e);
    }
  };

  const handleNewClick = () => {
    return navigate("/admin/registrants/edit", { state: {} });
  };
  const handleEditClick = (obj: any) => {
    return navigate("/admin/registrants/edit", { state: obj });
  };

  return (
    <div className="max-width w-100 me-auto ms-auto mt-5">
      <Breadcrumb label={"Registrants"} path={"admin"} />
      <div className="d-flex align-items-baseline">
        <h1 className="me-5">Registrants</h1>

                <div className="d-flex ms-auto">
                    <button className="secondary me-2" onClick={handleNewClick}>New</button>
                    <button className="me-5 deleteButton" onClick={handleSelectedDelete}>Delete</button>
                    <LoadByDate mutate={mutate} />
                    <EmailNotifications selectedArray={selectedArray} />
                    {selectedArray.length > 0 ?
                        <ExportExcel excelData={selectedArray} filename={'CEP-Registrants'}/>:
                        <button className=" reportButton secondary disabled me-2" disabled >Create a Report</button>}
                    <Filters filterArr={filterArr} setFilterArr={setFilterArr} filterKeys={filterKeys} />
                </div>

            </div>
            <div className="table-wrapper">
                {data && <AdminTable
                    data={tableData}
                    selectedArray={selectedArray}
                    setSelectedArray={setSelectedArray}
                    handleEditClick={handleEditClick}
                    handleIndividualDelete={handleIndividualDelete}
                    handleStatusEvent={handleStatusEvent}
                    filterArr={filterArr}
                    setFilterArr={setFilterArr}
                />}
            </div>

        </div>
    );
};

export default Registrants;
