import { useLocation} from "react-router-dom";
import { ContentBlocks } from "../../interface/contentBlockProps";
import {
  updateContentBlocks,
  addContentBlocks,
} from "../../services/ContentBlocks";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./ContentBlocks.scss";
import ReactQuill from "react-quill";
import { RTEModules, RTEformats } from "../Shared/RTEFields";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const EditContentBlocks = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
  } = useForm<ContentBlocks>({
    defaultValues: state,
  });

  const onSubmit: SubmitHandler<ContentBlocks> = async (contentBlocks) => {
    const resp = contentBlocks.id
      ? await updateContentBlocks(contentBlocks)
      : await addContentBlocks(contentBlocks);
    resp?.status === 200
      ? navigate("/admin/content-blocks")
      : alert("Something went wrong. Item not added.");
    return;
  };

  return (
    <div className="editContentBlocks max-width w-100 me-auto ms-auto mt-5">
      <Breadcrumb
        label={"Content Blocks"}
        path={"admin/content-blocks"}
        label2={"Add/Edit Content Blocks"}
      />

      <h1 className="me-5">Edit Content Blocks</h1>
      <div className="contentBlocksForm d-block w-100">
        <form className="form d-block" onSubmit={handleSubmit(onSubmit)}>
          <div className="body row">
            <span className="divider">Basic Information</span>
            <hr />
            <div className="row">
              <div className="col-lg-6 col-12">
                <label>Title</label>
                <input {...register("title", { required: true })} />
              </div>
              <div className="col-lg-6 col-12">
                <label>Spanish Title</label>
                <input {...register("title_es", { required: true })} />
              </div>
            </div>

            <span className="divider">Body</span>
            <hr />
            <div className="row">
              <div className="col-lg-12 col-12">
                <label>Content</label>
                <br />
                <Controller
                  control={control}
                  name="content"
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactQuill
                      theme="snow"
                      onChange={onChange}
                      modules={RTEModules}
                      formats={RTEformats}
                      value={value || ""}
                    />
                  )}
                />
              </div>
              <div className="col-lg-12 col-12">
                <label>Spanish Content</label>
                <br />
                <Controller
                  control={control}
                  name="content_es"
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactQuill
                      theme="snow"
                      onChange={onChange}
                      modules={RTEModules}
                      formats={RTEformats}
                      value={value || ""}
                    />
                  )}
                />
              </div>
            </div>

            <span className="divider">Options</span>
            <hr />
            <div className="col-lg-6 col-12">
              <label>Display Order</label>
              <div>
                <input
                  type="number"
                  {...register("sort_order", { required: true })}
                />
              </div>
              <label>Display On</label>
              <br></br>
              <input type="checkbox" {...register("login_page")} />
              <span>Login page</span>
              <br></br>
              <input type="checkbox" {...register("registration_page")} />
              <span>Registration page</span>
              <br></br>
              <input type="checkbox" {...register("thankyou_page")} />
              <span>Thank you page</span>
            </div>

            <span className="divider">Color Options</span>
            <hr />
            <div className="row">
              <div className="col-lg-6 col-12">
                <label>Text Color</label>
                <input
                  type="color"
                  {...register("color", { required: true })}
                />
              </div>
              <div className="col-lg-6 col-12">
                <label>Background Color</label>
                <input
                  type="color"
                  {...register("bgColor", { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="submit">
            <div className="max-width w-100 me-auto ms-auto d-flex">
              <button className="secondary" onClick={handleSubmit(onSubmit)}>
                Save & Close
              </button>
              <button
                className="ms-auto"
                onClick={() => {
                  navigate("/admin/content-blocks");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditContentBlocks;
