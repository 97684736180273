import { LoginUser, setUsers } from "../interface/admin";
import http from "./http";
import jwt from "jwt-decode";
import { AxiosResponse } from "axios";

export interface IUser {
    company?: string;
}

export const LoginAdmin = async (loginInfo: LoginUser) => {
    try {
        const Login = await http.post(`/admins/login`, loginInfo);
        sessionStorage.setItem("token", Login.data.token);
        window.location.pathname = "/admin";
        return Login;
    } catch (e) {
        return e;
    }
};

export const verifyUser = async (token: string) => {
    try {
        const response = await http.get(`/admins/verify/${token}`);
        return response;
    } catch (e) {
        console.log(e);
    }
};

export const checkToken = (): string | boolean | IUser => {
    if (sessionStorage.token) {
        try {
            const jwtToken = jwt(sessionStorage.token) as { exp: number; sub: string };
            if (Date.now() > jwtToken.exp * 1000) {
                sessionStorage.removeItem("token");
                return false;
            } else {
                return jwt(sessionStorage.token);
            }
        } catch (e) {
            sessionStorage.removeItem("token");
            return false;
        }
    }
    return false;
};

export const setToken = (setUser: setUsers["setUser"]): boolean => {
    if (sessionStorage.token) {
        setUser(jwt(sessionStorage.token) as string);
        return true;
    }
    return false;
};

export const checkAuthenticated = async (): Promise<void> => {
    try {
        // check for tokens in request header
        const res: AxiosResponse<boolean> = await http.get<boolean>(`/admins/is-verify`);
        //console.log(res);
        const isAuthenticated: boolean = res.data === true;
        if (!isAuthenticated) {
            sessionStorage.removeItem("token");
        }
    } catch (err: any) {
        console.error(err.message);
    }
};

export const getUserData = async (route: string) => {
    try {
        const response = await http.get(route);
        return response.data;
    } catch (e) {
        console.log(e);
    }
};
