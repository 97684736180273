import React, { useState } from "react";
import "./Registrants.scss";
import { ButtonToolbar, DropdownButton } from 'react-bootstrap';
import {FiMail} from 'react-icons/fi';

const EmailNotifications = (props: any) => {
  let [showSpinner, setShowSpinner] = useState(false);

  // @ts-ignore
  const controlEmail = async (selected: Array<any>, type:string) => {
      setShowSpinner(true);
      setShowSpinner(false);

  };
  
    return (
        <ButtonToolbar className='emailNotification'>
            <DropdownButton title={<FiMail/>} id={"dropdown-no-caret"} disabled={props.selectedArray.length === 0} >
              <div className='d-block'>
                {/* @ts-ignore */}
                <button className=' sendButton mt-3 mb-3 ms-3 me-3' onClick={()=>{controlEmail(props.selectedArray, 'quarterly')}}>{!showSpinner? `Send Quarterly Email  (${props.selectedArray.length})`: <div className="lds-dual-ring"></div>}</button>
               {/* @ts-ignore */}
                <button className='  sendButton mb-3 ms-3 me-3' onClick={()=>{controlEmail(props.selectedArray, 'reminder')}}>{!showSpinner? `Send Reminder Email  (${props.selectedArray.length})`: <div className="lds-dual-ring"></div>}</button>
              </div>
            </DropdownButton>
        </ButtonToolbar>
    )
}

export default EmailNotifications;