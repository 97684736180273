import { useState, FC } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Registrant, RegisterFormProps } from "../../interface/registrants";
import "../Register/Register.scss";
import { postRegistrant } from "../../services/register";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const RegisterFormMember: FC<RegisterFormProps> = ({
	company_id,
}) => {
	const [hide, setHide] = useState(false);
	const [email, setEmail] = useState("");
	const params = useParams();
	const {
		register,
		handleSubmit,
	} = useForm<Registrant>();

	const onSubmit: SubmitHandler<Registrant> = async (registrant) => {
		registrant.company_id = company_id; //this will  eventually be the company ID I get passed from the previous page.
		registrant.status = "Pending"; //default value
		setEmail(registrant.email);
		let resp: any = await postRegistrant(registrant);
		switch (resp.status) {
			case 200:
				setHide(true);
				break;
			case 500:
				toast.error(resp.data, {
					position: "bottom-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					theme: "colored",
				});
				break;
		}
		return;
	};

	return (
		<div className='registerForm mb-5'>
			<div className='header'>
				<p className={!hide ? "d-block" : "d-none"}>
					{params.locale === "es"
						? "Registre su cuenta"
						: "Register Your Account"}
				</p>
				<p className={!hide ? "d-none" : "d-block"}>
					{params.locale === "es"
						? "Gracias por inscribirse en nuestro Programa de Empleados Clientes"
						: "Thank you for registering for our Client Member Program"}
				</p>
			</div>
			<form
				className={"form " + (hide ? "d-none" : "d-block")}
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className='body row'>
					<div className='col-lg-4 col-12 mt-4'>
						<label>
							{params.locale === "es"
								? "Nombre del empleado"
								: "Member First Name"}
						</label>
						<input {...register("first_name", { required: true })} />
					</div>
					<div className='col-lg-4 col-12 mt-4'>
						<label>
							{params.locale === "es"
								? "Apellido del empleado"
								: "Member Last Name"}
						</label>
						<input {...register("last_name", { required: true })} />
					</div>
					<div className='col-lg-4 col-12 mt-4'>
						<label>
							{params.locale === "es"
								? "Nombre del titular de la cuenta Reliant"
								: "Reliant Account Holder Name"}
						</label>
						<input {...register("account_holder")} />
						<span>
							*{" "}
							{params.locale === "es"
								? "Si es diferente del nombre del empleado"
								: "If different from the member name"}
						</span>
					</div>
					<div className='col-lg-6 col-12 mt-4'>
						<label>
							{params.locale === "es"
								? "Correo electrónico preferido"
								: "Preferred Email"}
						</label>
						<input {...register("email", { required: true })} />
					</div>
					<div className='col-lg-6 col-12 mt-4'>
						<label>ESI ID </label>
						<input {...register("esid", { required: true })} />
					</div>
					<div className='col-lg-5 col-10 mt-4'>
						<label>
							{params.locale === "es"
								? "Número de cuenta Reliant"
								: "Reliant Account Number"}
						</label>
						<input {...register("ca_number", { required: true })} />
					</div>
					<div className='col-lg-4 col-12 mt-4'>
						<label>
							{params.locale === "es"
								? "Preferencia lingüística"
								: "Language Preference"}
						</label>
						<select
							className='d-block'
							{...register("language_preference", { required: true })}
						>
							<option value='English'>English</option>
							<option value='Spanish'>Spanish</option>
						</select>
					</div>
				</div>
				<div className='submit'>
					<button type='submit'>
						{params.locale === "es" ? "Regístrese" : "Register"}
					</button>
				</div>
			</form>
			<div className={"thankyou " + (!hide ? "d-none" : "d-block")}>
				<p>
					{" "}
					{params.locale === "es"
						? "Se ha enviado un correo electrónico de confirmación a"
						: "A confirmation email has been sent to "}{" "}
					<span className='font-weight-bold'>{email}</span>
				</p>
				<p>
					{params.locale === "es"
						? "Una vez que se haya inscrito en la facturación electrónica, su solicitud será revisada y procesada en un plazo de 10 días laborables. Una vez aprobada, le enviaremos un correo electrónico de confirmación adicional. Mientras tanto, si tiene alguna pregunta, envíenos un correo electrónico a "
						: "After you have enrolled in Paperless Billing, your request will be reviewed and processed within 10 business days. Once approved, we will send you an additional confirmation email. In the meantime, should you have questions, please email us at "}
					<span className='font-weight-bold'>service@reliant.com</span>{" "}
					{params.locale === "es" ? "o llámenos al" : "or call us at "}{" "}
					<span className='font-weight-bold'>1-866-RELIANT</span>.
				</p>
			</div>
		</div>
	);
};

export default RegisterFormMember;
