// @ts-ignore
import { useLocation } from "react-router-dom";
import { Faqs } from "../../interface/faqs";
import { updateFaq, addFaqs } from "../../services/faq";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { RTEModules, RTEformats } from "../Shared/RTEFields";
import "react-quill/dist/quill.snow.css";
import ItemHistory from "../ItemHistory/ItemHistory";
import "./Faqs.scss";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const EditFaq = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
  } = useForm<Faqs>({
    defaultValues: state,
  });

  const onSubmit: SubmitHandler<Faqs> = async (faq) => {
    const resp = faq.id ? await updateFaq(faq) : await addFaqs(faq);
    //reset();
    resp?.status === 200
      ? navigate("/admin/faqs")
      : alert("Something went wrong with submission ");
  };

  return (
    <div className="editFaqs max-width w-100 me-auto ms-auto mt-5">
      <Breadcrumb label={"FAQs"} path={"admin/faqs"} label2={"Add/Edit FAQs"} />

      <h1 className="me-5">{state.id ? "Edit " : "New "} FAQ</h1>
      {state.id && <ItemHistory table_id={"faq_items"} item_id={state.id} />}
      <div className="registerForm d-block w-100">
        <form className="form d-block">
          <div className="body row">
            <span className="divider">FAQ Question</span>
            <hr />
            <div className="row">
              <div className="col-lg-6 col-18">
                <label>Question</label>
                <br />
                <input
                  className=""
                  {...register("question", { required: true })}
                />
              </div>
              <div className="col-lg-6 col-18">
                <label>Spanish Question</label>
                <br />
                <input
                  className=""
                  {...register("question_es", { required: true })}
                />
              </div>
            </div>
            <span className="divider">FAQ Answer</span>
            <hr />
            <div className="row">
              <div className="col-lg-12 col-18">
                <label>Answer</label>
                <br />
                <Controller
                  control={control}
                  name="answer"
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactQuill
                      theme="snow"
                      onChange={onChange}
                      modules={RTEModules}
                      formats={RTEformats}
                      value={value || ""}
                    />
                  )}
                />
              </div>
              <div className="col-lg-12 col-18">
                <label>Spanish Answer</label>
                <br />
                <Controller
                  control={control}
                  name="answer_es"
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactQuill
                      theme="snow"
                      onChange={onChange}
                      modules={RTEModules}
                      formats={RTEformats}
                      value={value || ""}
                    />
                  )}
                />
              </div>
            </div>
            <span className="divider">Sort Order</span>
            <hr />
            <div className="row">
              <div className="col-lg-4 col-12">
                <label>Sort Order</label>
                <br />
                <input
                  type="number"
                  style={{ width: "100%" }}
                  {...register("sort_order", { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="submit">
            <div className="max-width w-100 me-auto ms-auto d-flex">
              <button className="secondary" onClick={handleSubmit(onSubmit)}>
                Save & Close
              </button>
              <button
                className="ms-auto"
                onClick={() => {
                  navigate("/admin/faqs");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditFaq;
