import "./Navbar.scss";
import { Nav, Navbar } from "react-bootstrap";
import { Outlet, Link, useParams } from "react-router-dom";
import reliantLogo from "../../img/reliant_logo.png";
import {useLocation} from "react-router-dom";

const NormalNavbar = () => {
  
  const location = useLocation();
  const params = useParams();

  return (
    <>
      <Navbar className="user-navbar max-width" expand="lg">
          <Navbar.Brand href={params.locale==='es'? "/es" : '/'}>
            <img src={reliantLogo} alt="Reliant Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="navbar-content">
              {params.locale === 'es' && location.pathname !== "/es" &&
              <Link className="nav-link" to="/es">Inicio</Link>}
              {params.locale !== 'es' && location.pathname !== "/" &&
              <Link className="nav-link" to="/">Home</Link>}
              {/* Nav.Link is used instead of Link because its an external link. Link is used for internal routing */}
              {params.locale === 'es' ? <Nav.Link href={'https://www.reliant.com/es/residential/help-support/contact'}>Contáctenos</Nav.Link> : <Nav.Link href={'https://www.reliant.com/en/residential/help-support/contact'}>Contact Us</Nav.Link>}
              {params.locale === 'es' ? <Link className={'nav-link'} to="/">English</Link> : <Link className={'nav-link'} to="/es">En Español</Link>}
            </Nav>
          </Navbar.Collapse>
      </Navbar>
      <Outlet />
    </>
  );
};

export default NormalNavbar;
