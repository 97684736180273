import useSWR from 'swr';
import { useLocation } from 'react-router-dom';
import { Registrant } from '../../interface/registrants';
import { updateRegistrant, addRegistrant } from "../../services/registrants";
import { getCompanyList } from "../../services/Company";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./Registrants.scss";
import ItemHistory from "../ItemHistory/ItemHistory";
import Breadcrumb from "../Breadcrumb/Breadcrumb";


const EditRegistrant = () => {
    const { state } = useLocation();
    const { data }: any = useSWR('getCompanyList', getCompanyList);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
    } = useForm<Registrant>({
        defaultValues: state
    });


    const onSubmit: SubmitHandler<Registrant> = async (registrant) => {
        const resp = registrant.id ? await updateRegistrant(registrant) : await addRegistrant(registrant);
        resp?.status === 200 ? navigate('/admin/registrants') : alert('Something went wrong with submission');
        return
    };

    return (
        <div className="editRegistrant max-width w-100 me-auto ms-auto mt-5">
      <Breadcrumb label={"Registrants"} path={"admin/registrants"} label2={"Add/Edit Registrants"} />
            <h1 className="me-5">{state.id ? "Edit " : "New "} Registrant</h1>
            {state.id && <ItemHistory table_id={'registrants'} item_id={state.id} />}
            <div className='registerForm d-block w-100'>
                <form className='form d-block' onSubmit={handleSubmit(onSubmit)}>
                    <div className='body row'>
                        <span className="divider">Company</span>
                        <hr />
                        <div className="row">
                            <div className='col-lg-4 col-12'>
                                <label>Company</label>
                                {data && <select {...register("company_id")}>
                                    {data.map((company: any) => {
                                        return (
                                            <option value={company.id}>{company.name}</option>
                                        );
                                    })}
                                </select>}
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-lg-4 col-12'>
                                <label>Reliant Account Holder Name</label>
                                <input {...register("account_holder")} />
                                <span className="d-block mt-0">*If different from the employee name</span>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <label>Employee ID or Work Email"</label>
                                <input {...register("employment_identifier", { required: true })} />
                            </div>
                        </div>
                        <span className="divider">System Status</span>
                        <hr />
                        <div className="row"></div>
                        <div className="col-lg-4 col-12">
                            <select {...register("status")}>
                                <option value="Pending">Pending</option>
                                <option value='Active'>Active</option>
                                <option value='Suspended'>Suspended</option>
                                <option value='Unverified'>Unverified</option>
                                <option value='Refused'>Refused</option>
                                <option value='Reminder'>Reminder</option>
                                <option value='Quarterly'>Quarterly</option>
                                <option value='Error'>Error</option>
                            </select>
                        </div>

                        <span className="divider">Personal Information</span>
                        <hr />
                        <div className="row">
                            <div className='col-lg-4 col-12'>
                                <label>Employee First Name</label>
                                <input {...register("first_name", { required: true })} />
                            </div>
                            <div className='col-lg-4 col-12'>
                                <label>Employee Last Name"</label>
                                <input {...register("last_name", { required: true })} />
                            </div>

                            <div className='col-lg-6 col-12'>
                                <label>Preferred Email</label>
                                <input {...register("email", { required: true })} />
                            </div>
                            <div className='col-lg-4 col-12'>
                                <label>Language Preference"</label>
                                <select className='d-block' {...register("language_preference", { required: true })}>
                                    <option value="English">English</option>
                                    <option value="Spanish">Spanish</option>
                                </select>
                            </div>
                        </div>
                        <span className="divider">Account Information</span>
                        <hr />
                        <div className="row">
                            <div className='col-lg-6 col-12'>
                                <label>ESI ID </label>
                                <input {...register("esid", { required: true })} />
                            </div>
                            <div className='col-lg-5 col-10'>
                                <label>Reliant Account Number"</label>
                                <input {...register("ca_number", { required: true })} />
                            </div>
                            <div className='col-lg-1 col-2'>
                                <label>##</label>
                                <input {...register("check_digit", { required: true })} />
                            </div>
                        </div>
                    </div>
                    <div className='submit'>
                        <div className="max-width w-100 me-auto ms-auto d-flex">
                            <button className="secondary" onClick={handleSubmit(onSubmit)}>Save & Close</button>
                            {/* <button className=" secondary ms-5" type='submit'>Save and Close</button> */}
                            <button className="ms-auto" onClick={() => { navigate('/admin/registrants') }} >Close</button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditRegistrant;
