import { useParams } from 'react-router-dom'
import './Footer.scss'
import { AiFillFacebook, AiFillLinkedin, AiOutlineTwitter, AiFillYoutube } from 'react-icons/ai'

const Footer = () => {
  const params = useParams();
return (
    <div className='footer'>
      <div className='max-width me-auto ms-auto d-lg-flex d-block w-100'>
        <div className='footer-content'>
          <div className='footer-nav'>
            {params.locale === 'es' ? <a target="_blank" rel="noreferrer" href='https://www.reliant.com/es/about/inside-reliant/who-we-are'>Sobre Reliant</a> : <a target="__blank" href='https://www.reliant.com/en/about/inside-reliant/who-we-are'>About Reliant</a>}

            {params.locale === 'es' ?  <a target="_blank" rel="noreferrer" href='https://www.reliant.com/es/Page/Generic/Public/about_us_markets_we_serve_page.jsp'>Áreas donde servimos</a> : <a target="_blank" rel="noreferrer" href='https://www.reliant.com/en_US/Page/Generic/Public/about_us_markets_we_serve_page.jsp'>Markets We Serve</a> }
            {params.locale === 'es' ?  <a target="_blank" rel="noreferrer" href='https://news.reliant.com/overview/default.aspx'>Sala de Prensa</a> :  <a target="_blank" rel="noreferrer" href='https://news.reliant.com/overview/default.aspx'>Newsroom</a> }
            {params.locale === 'es' ?  <a target="_blank" rel="noreferrer" href='https://www.reliant.com/es/about/community'>Communidad</a> :  <a target="_blank" rel="noreferrer" href='https://www.reliant.com/en/about/community'>Community</a> }
            {params.locale === 'es' ?  <a target="_blank" rel="noreferrer" href='https://www.reliant.com/es/about/careers'>Empleos</a> :  <a target="_blank" rel="noreferrer" href='https://www.reliant.com/en/about/careers'>Careers</a> }
            {params.locale === 'es' ?  <a target="_blank" rel="noreferrer" href='https://www.reliant.com/es/about/legal/privacy-statement'>Derechos de privacidad</a> :  <a target="_blank" rel="noreferrer" href='https://www.reliant.com/en/about/legal/privacy-statement'>Privacy Policy</a> }
            {params.locale === 'es' ?  <a target="_blank" rel="noreferrer" href='https://www.reliant.com/es/about/legal/terms-of-use'>Términos de uso</a> :  <a target="_blank" rel="noreferrer" href='https://www.reliant.com/en/about/legal/terms-of-use'>Terms of Use</a> }
          </div>
          <span>© 2023 Reliant Energy Retail Holdings, LLC.   All rights reserved.<span className='ms-3 me-3'>|</span>910 Louisiana Street    <span className='ms-3 me-3'>|</span>    Houston, TX 77002    <span className='ms-3 me-3'>|</span>    PUC Certificate #10007</span>
        </div>
        <div className='footer-socials'>
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/reliantenergy"><AiFillFacebook /></a>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/ReliantEnergy"><AiFillLinkedin /></a>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/reliant-energy/"><AiOutlineTwitter /></a>
          <a target="_blank" rel="noreferrer" href="https://www.youtube.com/ReliantEnergy"><AiFillYoutube /></a>
        </div>
      </div>
    </div>
  )
}

export default Footer