import { useForm, SubmitHandler } from "react-hook-form";
import { LoginUser } from "../../interface/admin";
import { LoginAdmin } from "../../services/auth";
import "./Login.scss";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginUser>();
  const onSubmit: SubmitHandler<LoginUser> = async (data) => {
    await LoginAdmin(data);
  };

  return (
    <div className="login">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pb-2">
          <label htmlFor="name">Username: </label>
          <input id="name" {...register("name", { required: true })} />
          {errors.name && <span>This field is required</span>}
        </div>
        <div className="pb-2">
          <label htmlFor="password">Password: </label>
          <input
            type="password"
            id="password"
            {...register("password", { required: true })}
          />
          {errors.password && <span>This field is required</span>}
        </div>
        <div className="submit">
          <input type="submit" />
        </div>
      </form>
    </div>
  );
};

export default Login;
