import "./Home.scss";
import { useParams } from "react-router-dom";
import Footer from "../Navigation/Footer";
import Terms from "./TermsMember";
import Customer from "./CustomerMember";
import NotCustomer from "./NotCustomerMember";
import ContentBlockMember from "./ContentBlockMember";
import Faq from "./FaqMember";

const HomeMember = () => {
  const params = useParams();

  return (
    <div>
      <main>
        <div className="hero">
          <div className="callout-box">
            <h1>Reliant Client Member Program</h1>
            <h4>
              {params.locale === "es"
                ? "Comience a ahorrar ahora"
                : "Start saving today"}
            </h4>
          </div>
        </div>
        <div className="customer-cta flex-lg-row flex-column">
          <Customer />
          <div className="vr d-lg-block d-none"></div>
          <hr className="d-lg-none d-block" />
          <NotCustomer />
        </div>
        <ContentBlockMember page={"login_page"} />
        <Faq />
        <Terms />
      </main>
      <Footer />
    </div>
  );
};

export default HomeMember;