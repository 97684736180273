import BSBreadcrumb from "react-bootstrap/Breadcrumb";

const Breadcrumb = (props: any) => {
  return (
    <BSBreadcrumb>
      <BSBreadcrumb.Item href="/admin">Admin Dashboard</BSBreadcrumb.Item>
      <BSBreadcrumb.Item
        href={"/" + props.path}
        active={props.label2 ? false : true}
      >
        {props.label}
      </BSBreadcrumb.Item>
      {
        props.label2 && (
          <BSBreadcrumb.Item
            href={"/" + props.path}
            active={props.label2 ? true : false}
          >
            {props.label2}
          </BSBreadcrumb.Item>
        )
      }
    </BSBreadcrumb>
  );
};
export default Breadcrumb;
